/*---------- Main Application Div --------*/

:root {

  --primary-color: #1A3D6B;
  --secondary-color: #0088CB;

}

h1, h2, h3, h4, h5, h6 {
  color: var(--primary-color);
  
}

body {

  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.error-text-container {
  
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0 0;
 
  
}

.error-text {
  color: red;
  background-color: rgba(236, 236, 236, 0.733);
  border-radius: 10px;
  border: solid 1px red;
  padding: 5px;
  margin: 5px 0;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */

}

.error-heading {
  color : red;
}

.fa-spin {
  animation: spinner 1s linear infinite;

}

.confirm-button {
  background-color: var(--primary-color);
  height: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  color: white;
  margin: 10px 0;
}

.cancel-button {
  background-color: lightgray;
  height: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  color: black;
  margin: 10px 0;
}
 


/* Add this for the loading circle */
@keyframes spinner {
  to { transform: rotate(360deg); }
}
/**/





/**/

.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow:hidden;
}




.application-component-container {
  display: flex;
  height: 100vh;
  width: 100%;
}

.application-container {
  display: flex;
  width: 100%;
  height: 100vh;
}

.application-layout {
  display: flex;
  width: 100%;
  height: 100vh;
}

.app-header-container {
  height: 100%;
  width: 200px; /* Default width */
  transition: width 0.3s ease; /* Smooth transition for width change */
}

.app-header-container.collapsed {
  width: 80px; /* Collapsed width */
  transition: width 0.3s ease; /* Smooth transition for width change */
}

.main-content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  
}

.app-user-info-header-container {
  width: 95%;
  padding: 1% 0;
  height: 100px; /* Adjust the height as per your design */
  margin: 0 auto;
  
}

.app-routes-main-container {
  width: 100%;
  margin: 0 auto;
  padding: 0; /* Adjust padding as needed */
  overflow-y: auto;
  height: 100%;
  
}


/*-------------------------------------------------------*/



/* ----------------------------------------------
          Login page Component                  
  ----------------------------------------------*/

.component-container{
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  
  overflow-y: scroll;
  
  

}

.login-component-container {

  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  
}

/* Desktop Login */

.login-component-desktop {

  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

}

.login-component-left {

  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 2% 0;

}



.login-logo{

  width: 300px;
  height: 150px;
}

.login-text-container {
  color: white;
  font-size: 30px;
  font-weight: lighter;
  width: 70%;
  text-align: center;
}

.login-contact-container {

  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: center;
}

.login-social-media-container {

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.social-media-icon {
  fill: white;
  height: 30px;
  width: 30px;

}


.login-equiries-container {
  color : white
}
/* Right side of the login page*/

.login-component-right-component-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color)); /* Change the colors as needed */
  
}

.login-component-right-container {
  display: flex;
  align-items: center;
  background-color: white;
  height: 80%;
  width: 80%;
  border-radius: 15px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  
}

.login-right-header-text {

  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  

}

.login-right-header-text h1 {

  color : var(--primary-color)
}

/* login form */

.login-form-container {

  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  
  
}

.login-form-body-container {
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  width: 90%;
  height: 400px;
  

}

.login-form-input-container {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
}

.login-form-input-icon {

  height: 25px;
  width: 25px;
  margin: auto;
}

.login-form-input {

  width: 350px;
  height: 20px;
  border-radius: 5px;
  margin: auto 0;
  border: 1px solid grey

}

.login-terms-and-conditions p{
  font-size: 10px;

}

.login-form-button-container {

  display: flex;
  height: 50px;
}

.login-form-button {

  background-color: var(--primary-color);
  color: white;
  border-radius: 5px;
  margin: auto 0;
  border: none;
  height: 35px;
  width: 70px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */

}


/* ------------------------------------- 
            Mobile Login
--------------------------------------*/

/* --------- Smartphones (Portrait) ------------------*/
@media (min-width: 320px) and (max-width: 479px) {

  
.login-component-mobile {

  background: linear-gradient(to right, var(--primary-color), var(--secondary-color)); /* Change the colors as needed */
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: scroll;
  
}

.login-enquiries-container {
  color: white;
}

.login-mobile-logo-container {

  
  display: flex;
  width: 80%;
  margin: 0 auto;

  
}

.login-logo-mobile {

  width: 250px;
  height: 120px;
  margin: 0 auto;
}

.login-header-mobile-text-container {
  
  display: flex;
  width: 85%;
  margin: 0 auto;
  padding: 10px 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  color: white;
}


/*----- mobile login form ----- */

.login-mobile-form-container {

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 80%;
  height: 55%;
  background-color: white;
  border-radius: 20px;
  margin: 0 auto;

}

.login-mobile-form {
  
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}


.login-mobile-form-element-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  padding: 5px 0;
 
  
}

.login-mobile-form-input-container {
  
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  
}

.login-mobile-form-input {
  width: 100%;
  border: 1px solid var(--primary-color);
  border-radius: 10px;
}



.login-mobile-terms-and-conditions-container {

  
  display: flex;
  margin: 0 auto;
  width: 90%;
  text-align: center;
  font-size: 10px;
  padding: 5px 0;
}

.login-mobile-form-button-container {

  
  display: flex;
  width: 80%;
  margin: 0 auto;
  padding: 10px 0;
  

}

.login-mobile-contact-container {

  
  width: 80%;
  margin: 0 auto;
  

}

.login-mobile-social-media-container {
  display: flex;
  width: 30%;
  margin: 0 auto;
  justify-content: space-between;
}

.login-mobile-enquiries-container {
  display: flex;
  width: 80%;
  margin: 0  auto;
  text-align: center;
  flex-direction: column;
  color: white;
}

}

/* --------- /* Smartphones (Landscape) */ 
@media (min-width: 480px) and (max-width: 599px) {
  

  .login-component-mobile {

    background: linear-gradient(to right, var(--primary-color), var(--secondary-color)); /* Change the colors as needed */
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: scroll;
    
    
  }
  
  .login-enquiries-container {
    color: white;
  }
  
  .login-mobile-logo-container {
  
    
    display: flex;
    width: 80%;
    margin: 0 auto;
  
    
  }
  
  .login-logo-mobile {
  
    width: 250px;
    height: 120px;
    margin: 0 auto;
  }
  
  .login-header-mobile-text-container {
    
    display: flex;
    width: 80%;
    margin: 0 auto;
    padding: 10px 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: white;
  }
  
  
  /*----- mobile login form ----- */
  
  .login-mobile-form-container {
  
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 80%;
    background-color: white;
    border-radius: 20px;
    margin: 0 auto;
  
  }
  
  .login-mobile-form {
    
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  
  .login-mobile-form-element-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    padding: 5px 0;
   
    
  }
  
  .login-mobile-form-input-container {
    
    display: flex;
    flex-direction: row;
    width: 98%;
    margin: 0 auto;
    
  }
  
  .login-mobile-form-input {
    width: 85%;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
  }
  
  
  
  .login-mobile-terms-and-conditions-container {
  
    
    display: flex;
    margin: 0 auto;
    width: 80%;
    text-align: center;
    font-size: 16px;
    padding: 5px 0;
  }
  
  .login-mobile-form-button-container {
  
    
    display: flex;
    width: 80%;
    margin: 0 auto;
    padding: 10px 0;
    
  
  }
  
  .login-mobile-contact-container {
  
    
    width: 80%;
    margin: 0 auto;
    
  
  }
  
  .login-mobile-social-media-container {
    display: flex;
    width: 30%;
    margin: 0 auto;
    justify-content: space-between;
  }
  
  .login-mobile-enquiries-container {
    display: flex;
    width: 80%;
    margin: 0  auto;
    text-align: center;
    flex-direction: column;
    color: white;
  }

}

/* Tablets (Portrait) */
@media (min-width: 600px) and (max-width: 800px) {

  
  .login-component-mobile {

    background: linear-gradient(to right, var(--primary-color), var(--secondary-color)); /* Change the colors as needed */
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: scroll;
    
    
  }
  
  .login-enquiries-container {
    color: white;
  }
  
  .login-mobile-logo-container {
  
    
    display: flex;
    width: 80%;
    margin: 0 auto;
  
    
  }
  
  .login-logo-mobile {
  
    width: 400px;
    height: 190px;
    margin: 0 auto;
  }
  
  .login-header-mobile-text-container {
    
    display: flex;
    width: 80%;
    margin: 0 auto;
    padding: 10px 0;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: white;
  }
  
  
  /*----- mobile login form ----- */
  
  .login-mobile-form-container {
  
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 80%;
    height: 40%;
    background-color: white;
    border-radius: 20px;
    margin: 0 auto;
  
  }
  
  .login-mobile-form {
    
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  
  .login-mobile-form-element-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    padding: 5px 0;
   
    
  }
  
  .login-mobile-form-input-container {
    
    display: flex;
    flex-direction: row;
    width: 98%;
    margin: 0 auto;
    
  }
  
  .login-mobile-form-input {
    width: 85%;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
  }
  
  
  
  .login-mobile-terms-and-conditions-container {
  
    
    display: flex;
    margin: 0 auto;
    width: 80%;
    text-align: center;
    font-size: 15px;
    padding: 5px 0;
  }
  
  .login-mobile-form-button-container {
  
    
    display: flex;
    width: 80%;
    margin: 0 auto;
    padding: 10px 0;
    
  
  }
  
  .login-mobile-contact-container {
  
    
    width: 80%;
    margin: 0 auto;
    
  
  }
  
  .login-mobile-social-media-container {
    display: flex;
    width: 30%;
    margin: 0 auto;
    justify-content: space-between;
  }
  
  .login-mobile-enquiries-container {
    display: flex;
    width: 80%;
    margin: 0  auto;
    text-align: center;
    flex-direction: column;
    color: white;
  }


}


/* Tablets (Landscape) and Low-Resolution Laptops/Desktops */
@media (min-width: 801px) and (max-width: 1024px) {

  
  
  .login-component-container {
    
    display: flex;
    flex-direction: column;
    height: 100%;
    
  }

  .login-component-left {

    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;
    padding: 2% 0;
  
  }
  
  
  .login-logo{
  
    width: 200px;
    height: 100px;
  }
  
  .login-text-container {
    color: white;
    font-size: 30px;
    font-weight: lighter;
    width: 80%;
    text-align: center;
  }
  
  .login-contact-container {
  
    display: flex;
    flex-direction: column;
    width: 50%;
    text-align: center;
  }
  
  .login-social-media-container {
  
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  
  .social-media-icon {
    fill: white;
    height: 30px;
    width: 30px;
  
  }
  
  
  .login-enquiries-container {
    color : white;
    font-size: 15px;
    width: 100%;
    
  }
  /* Right side of the login page*/
  
  .login-component-right-component-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background: linear-gradient(to right, var(--primary-color), var(--secondary-color)); /* Change the colors as needed */
    
  }
  
  .login-component-right-container {

    margin: 0 auto;
    background-color: white;
    height: 75%;
    width: 90%;
    border-radius: 15px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
    

  }

  

  .login-form {
    
    height: 100%;
  }
  
  .login-right-header-text {
  
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  
  }
  
  .login-right-header-text h1 {
  
    color : var(--primary-color)
  }
  
  /* login form */
  
  .login-form-container {
  
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    height: 40%;
    justify-content: space-evenly;
    
    
  }

  .login-form {

  }
  
  .login-form-body-container {
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    width: 90%;
    height: 350px;
  
  }
  
  .login-form-input-container {
  
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .login-form-input-icon {
  
    height: 25px;
    width: 25px;
    margin: auto;
  }
  
  .login-form-input {
  
    width: 300px;
    height: 20px;
    border-radius: 5px;
    margin: auto 0;
    border: 1px solid grey
  
  }
  
  .login-terms-and-conditions p{
    font-size: 10px;
  
  }
  
  .login-form-button-container {
  
    display: flex;
    height: 50px;
  }
  
  .login-form-button {
  
    background-color: var(--primary-color);
    color: white;
    border-radius: 5px;
    margin: auto 0;
    border: none;
    height: 35px;
    width: 70px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  
  }


}

/* Tablets (Landscape) and Low-Resolution Laptops/Desktops */
@media (min-width: 1025px) and (max-width: 1140px) {

  
  .login-component-container {
    
    display: flex;
    flex-direction: column;
    height: 100%;
   
  }

  .login-component-left {

    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 2% 0;
    
  
  }
  
  
  .login-logo{
  
    width: 400px;
    height: 200px;
  }
  
  .login-text-container {
    color: white;
    font-size: 35px;
    font-weight: lighter;
    width: 80%;
    text-align: center;
  }
  
  .login-contact-container {
  
    display: flex;
    flex-direction: column;
    width: 30%;
    text-align: center;
  }
  
  .login-social-media-container {
  
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  
  .social-media-icon {
    fill: white;
    height: 30px;
    width: 30px;
  
  }
  
  
  .login-enquiries-container {
    color : white;
    font-size: 15px;
    width: 100%;
    
  }

  .login-enquiries-container p{
    
    color : white;

  }

  /* Right side of the login page*/
  
  .login-component-right-component-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to right, var(--primary-color), var(--secondary-color)); /* Change the colors as needed */
    
  }
  
  .login-component-right-container {

    margin: 0 auto;
    background-color: white;
    height: 500px;
    width: 80%;
    border-radius: 15px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
 
    align-items: center;
    display: flex;
    padding: 5px 0;

  }

  .login-form-container-main {
    
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 500px;
    width: 95%;
    justify-content: space-evenly;
   
   
   
    
  }

  .login-form {
    
    height: 100%;
   
  }
  
  .login-right-header-text {
  
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  
  .login-right-header-text h1 {
  
    color : var(--primary-color);
    
  }
  
  /* login form */
  
  .login-form-container {
  
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    height: 50%;
   
    
  }

  .login-form {

  }
  
  .login-form-body-container {
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    width: 90%;
    height: 350px;
  
  }
  
  .login-form-input-container {
  
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .login-form-input-icon {
  
    height: 25px;
    width: 25px;
    margin: auto;
  }
  
  .login-form-input {
  
    width: 350px;
    height: 20px;
    border-radius: 5px;
    margin: auto 0;
    border: 1px solid grey
  
  }
  
  .login-terms-and-conditions p{
    font-size: 9px;
  
  }
  
  .login-form-button-container {
  
    display: flex;
    height: 50px;
  }
  
  .login-form-button {
  
    background-color: var(--primary-color);
    color: white;
    border-radius: 5px;
    margin: auto 0;
    border: none;
    height: 35px;
    width: 70px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  
  }


}


/* end of mobile -*/


/*----------------------------------------------
                  Dashboard
------------------------------------------------*/

.dashboard-component-container {

  display: flex;
  flex-direction: column;
  margin: auto;
  width: 90%;
  height: 90%;
  justify-content: space-between;
}

.welcome-text-container {

  display: flex;
  flex-direction: column;
  width: 95%;
  margin: 0 auto;
  text-align: center;

}

/* Application features
- checklist management
- manage users
- profile
- help */

.application-feature-main-container {
  
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 95%;
  height: 300px;
  margin: 0 auto;

}

.application-feature-container {

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: rgb(238, 238, 238);
  width: 190px;
  height: 220px;
  border-radius: 15px;
  text-align: center;
  border: 1px solid black;
  padding: 0 2%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease; /* Add transition for smooth scaling */
}

.application-feature-container:hover {

  transform: scale(1.05); /* Scale up on hover */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

}

.feature-img-container {

  height: 20%;
}

.feature-name-container {
  height: 20%;
  text-decoration: none;
}

.feature-description-container {
  height: 20%;
}

.feature-img {

  width: 50px;
  height: 50px;
  color: var(--primary-color);
}

.feature-description-container {
  font-size: 14px;
}

.link {
  text-decoration: none;
  color: black;
} 

.view-users-container {
  width: 90%;
 
  margin: 0 auto;
}

.power-bi-button-paragraph-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  background-color: rgb(231, 231, 231);
  border-radius: 10px;
}

.dashboard-main-component-container{
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  
  overflow-y: scroll;
  
  

}

.dashboard-questions {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 80%;
  margin: 0 auto;
  
}


