:root {

    --primary-color: #1A3D6B;
    --secondary-color:#0088CB;
  
  }
  
  body {
  
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  
  }

  /**/

  .lia-spin-solid-icon {
    animation: spinner 1s linear infinite;
    width: 50px;
    height: 50px;
    color: var(--secondary-color);
  }

  /* */

  .loading-component-main-container {

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999; /* Highest z-index */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(189, 189, 189, 0.5); /* Transparent background */
    transition: opacity 0.5s ease-in-out;
    animation: fadeIn 0.5s ease-in-out forwards;

}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.loading-toaster-container {

    background-color: white;
    border-radius: 50%;
    height: 400px;
    width: 400px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5); /* Adjust the values as needed */
    padding: 2% 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

}