/*---------- Main Application Div --------*/

:root {

  --primary-color: #1A3D6B;
  --secondary-color: #0088CB;

}

h1, h2, h3, h4, h5, h6 {
  color: var(--primary-color);
  
}

body {

  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.error-text-container {
  
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0 0;
 
  
}

.error-text {
  color: red;
  background-color: rgba(236, 236, 236, 0.733);
  border-radius: 10px;
  border: solid 1px red;
  padding: 5px;
  margin: 5px 0;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */

}

.fa-spin {
  animation: spinner 1s linear infinite;
}


/* Add this for the loading circle */
@keyframes spinner {
  to { transform: rotate(360deg); }
}
/**/

.add-ms-power-bi-input-power-bi-link {
  width: 70%;
}

.add-ms-power-bi-input-power-bi-name {
  border: 1px solid var(--primary-color);
}

.add-ms-power-bi-input-power-bi-link {
  border: 1px solid var(--primary-color);

}

/* -----------------------------------------------------
                  Edit Power Bi Report 
----------------------------------------------------*/
.edit-power-bit-toaster-container {

  background-color: white;
  border-radius: 25px;
  height: 450px;
  width: 600px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4); /* Adjust the values as needed */
  padding: 2% 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow-y: scroll;

}

.edit-power-bit-toaster-header-container {
  
  width: 90%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--primary-color);
  padding: 0 0 10px 0;
}

.edit-power-bit-toaster-form-id-container {
  color: grey;
  display: flex;
  justify-content: flex-end;
}

.edit-power-bit-toaster-link-input {
  width: 80%;
  border: 1px solid var(--primary-color);
}

.edit-power-bit-toaster-form-container {
  
  display: flex;
  flex-direction: column;
  width: 90%;
}

.edit-power-bit-toaster-form-element-container {
  
  display: flex;
  flex-direction: column;
  padding: 15px 0 ;
}

.edit-power-bit-toaster-button-container {
  display: flex;
  width: 90%;
  justify-content: space-evenly;
}